import __layout from 'nextra-theme-docs'
import __themeConfig from '/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/web/landing/theme.config.tsx'


import 'nextra-theme-docs/style.css'
import type { AppProps } from 'next/app'
import type { ReactElement } from 'react'
import '../styles.css'
import Layout from '../pages_components/layout'

export default function App({ Component, pageProps }: AppProps): ReactElement {
  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  )
}


const __nextra_internal__ = globalThis[Symbol.for('__nextra_internal__')] ||= Object.create(null)
__nextra_internal__.context ||= Object.create(null)
__nextra_internal__.Layout = __layout
__nextra_internal__.themeConfig = __themeConfig