import { Button } from '@chakra-ui/react'
import { getOctoBotCloudAppUrl } from '@astrolab/back/lib/urls'

export default function NavBarNextra({ ...rest }: { [rest: string]: any }) {
  return (
    <>
      <Button
        as={'a'}
        variant="brand"
        display={{ base: 'none', md: 'flex' }}
        className="max-md:_hidden"
        style={{
          minWidth: "75px",
        }}
        fontSize="sm"
        fontWeight="500"
        data-ph-capture-attribute-button-role="startYourOctoBot"
        href={`${getOctoBotCloudAppUrl()}/register`}
        aria-label="register"
        {...rest}
      >
        Register
      </Button>
    </>
  )
}
