import { BreadcrumbContent, getBreadcrumbItemsFromBreadcrumbContents, getNewsArticle } from "@astrolab/web/lib/json-ld"
import { removeTrailingSlash } from "@astrolab/web/lib/navigation"
import { JSONLDScript }  from "@astrolab/web/common/JsonLD"


const Breadcrumb = ({
  baseUrl,
  config,
  locale,
}: {
  baseUrl: string
  config: any
  locale: string
}) => {
  const pathWithoutSlash = removeTrailingSlash(baseUrl)
  const breadcrumbs: BreadcrumbContent[] = [{
    name: "OctoBot", 
    url: `${pathWithoutSlash}/${locale}`
  }]
  if (config.filePath.includes("/investing/") && !config.filePath.endsWith("/investing/introduction")){
    breadcrumbs.push({
      name: "Invest", 
      url: `${pathWithoutSlash}/${locale}/investing/introduction`
    })

  } else if (config.filePath.includes("/guides/") && !config.filePath.endsWith("/guides/octobot")){
    breadcrumbs.push({
      name: "Trading bot", 
      url: `${pathWithoutSlash}/${locale}/guides/octobot`
    })

  }
  const lastElementIndex = config.normalizePagesResult.activePath.length - 1
  config.normalizePagesResult.activePath.forEach((pathElement: any, index: null) => {
    // take pathElement.title when not in the last element to keep titles shorts
    const title = ((lastElementIndex == index && pathElement.frontMatter) ? (pathElement.frontMatter.title): "") || pathElement.title
    // ignore pages without pathElement.frontMatter as they don't exist
    if(pathElement.frontMatter && title){
      breadcrumbs.push({
        name: title, 
        url: `${pathWithoutSlash}${pathElement.route}`
      })
    }
  })
  return (
    <JSONLDScript 
      content={getBreadcrumbItemsFromBreadcrumbContents(breadcrumbs)}
    />
  )
}

const ArticleIfAny = ({
  baseUrl,
  config,
}: {
  baseUrl: string
  config: any
}) => {
  if(config.filePath.includes("/blog/")){
    const newsArticle = getNewsArticle(
      config.frontMatter.title,
      config.frontMatter.description,
      config.frontMatter.image,
      "Organization",
      "OctoBot cloud",
      `${baseUrl}/opengraph-image.png`,
      baseUrl,
      "Organization",
      "OctoBot cloud",
      `${baseUrl}/opengraph-image.png`,
      baseUrl,
    )
    return (
      <JSONLDScript 
        content={newsArticle}
      />
    )
  }
  return null
}


export default function NextraJSONDLGeneratedScripts({
  baseUrl,
  config,
  locale,
}: {
  baseUrl: string,
  config: any,
  locale: string,
}) {
  return (
    <>
      <Breadcrumb baseUrl={baseUrl} config={config} locale={locale}/>
      <ArticleIfAny baseUrl={baseUrl} config={config}/>
    </>
  )
}
