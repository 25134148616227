import { RootLayout } from '@astrolab/web/common/Layout'
import Providers from './providers'
import { DM_Sans } from 'next/font/google'

// needs to be declared here (importing it raises an error)
export const sourceCodeDMSans = DM_Sans({ weight: ['400', '500', '700'], subsets: ['latin']});

// disable next default metadata. Metadata are defined in theme.config.tsx#useNextSeoProps
const metadata = {};

export default function Layout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Providers>
      <RootLayout useBgColor={true} className={sourceCodeDMSans.className}>
        <div style={{letterSpacing: "-0.5px"}}>
          {children}
        </div>
      </RootLayout>
    </Providers>
  )
}
