import { FiMoon, FiSun } from "react-icons/fi";
import {
  Icon,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTheme } from "nextra-theme-docs";


export default function ThemeSwitch({ ...rest }: { [rest: string]: any }) {
  const { colorMode, setColorMode } = useColorMode();
  const { setTheme } = useTheme()
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const changeColorMode = () => {
    const newTheme = colorMode == "light" ? "dark" : "light"
    setTheme(newTheme)
    setColorMode(newTheme)
  }

  return (
    <Button 
      variant="no-hover" 
      bg="transparent" 
      onClick={changeColorMode}
      aria-label="toggle dark mode"
      style={{
        marginLeft: "-10px",
      }}
    >
      <Icon
        as={colorMode === "light" ? FiMoon : FiSun}
        style={{
          height: "20px",
          width: "20px",
          color: textColor,
        }}
      />
    </Button>
  )
}