import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react'
import OctoBotLogo from '@astrolab/web/ui/OctoBotLogo'
import { NextraLocalizedLink } from '@astrolab/web/common/LocalizedLink'

export default function ThemedOctoBotLogo() {
  const textColor = useColorModeValue('#0f1237', '#f3f6f8') // temp

  return (
    <Flex style={{display: "flex"}}>
      <Box style={{width: "50px"}}>
        <NextraLocalizedLink href="/" style={{width: "50px"}} textDecoration="none" aria-label="octobot cloud">
          <OctoBotLogo />
        </NextraLocalizedLink>
      </Box>

      <Box style={{marginTop: "auto", marginBottom: "auto"}}>
        <NextraLocalizedLink href="/" textDecoration="none" aria-label="octobot cloud">
          <Text
            display={{ base: 'none', md: 'flex' }}
            style={{fontWeight: "900", color: textColor, fontSize: '18px'}}
            className='max-md:_hidden'
          >
            OctoBot Cloud
          </Text>
        </NextraLocalizedLink>
      </Box>
    </Flex>
  )
}
