import { isExperimentalEnvironment } from "@astrolab/back/lib/site";
import { getLocalizedUrl } from "./locales";
export const getMetadata = (title, description, images, canonical, languages)=>{
    const usedTitle = title || "OctoBot";
    const usedDescription = description || "Stay ahead of the crypto game with OctoBot using automated strategies. Run the best trading strategies easily and for free with OctoBot.";
    const usedOGImages = images || [
        `${process.env.NEXT_PUBLIC_SITE_URL}/opengraph-image.png`
    ];
    const usedTwitterImages = images || [
        `${process.env.NEXT_PUBLIC_SITE_URL}/twitter-image.png`
    ];
    const shouldSkipIndexing = isExperimentalEnvironment();
    const builtMetadata = {
        title: usedTitle,
        description: usedDescription,
        openGraph: {
            title: usedTitle,
            description: usedDescription,
            images: usedOGImages,
            url: canonical ? canonical : process.env.NEXT_PUBLIC_SITE_URL,
            siteName: "OctoBot",
            type: "website"
        },
        twitter: {
            title: usedTitle,
            description: usedDescription,
            images: usedTwitterImages,
            site: "@DrakkarsOctoBot"
        }
    };
    if (canonical || languages) {
        builtMetadata.alternates = {};
        if (canonical) {
            builtMetadata.alternates.canonical = canonical;
        }
        if (languages) {
            const langs = {};
            languages.forEach((language)=>{
                langs[language.locale] = language.url;
            });
            builtMetadata.alternates.languages = langs;
        }
    }
    if (shouldSkipIndexing) {
        builtMetadata.robots = {
            index: false,
            follow: false
        };
    }
    return builtMetadata;
};
export const getLocalizedUrlAndPath = (rootUrl, path, params, defaultLocale)=>{
    return `${getLocalizedUrl(rootUrl, params.locale, defaultLocale)}${path}`;
};
