import NextraFooter from '@astrolab/web/common/Footer/NextraFooter'
import { getMetadata } from '@astrolab/web/lib/metadata'
import ThemeSwitch from './pages_components/ThemeSwitch'
import NavBarNextra from './pages_components/NavBarNextra'
import { useFSRoute } from 'nextra/hooks'
import { useConfig, type DocsThemeConfig } from 'nextra-theme-docs'
import { NextraLocaleSwitch } from '@astrolab/web/common/LocaleSwitch/NextraLocaleSwitch'
import ThemedOctoBotLogo from 'pages_components/ThemedOctoBotLogo'
import { isExperimentalEnvironment } from '@astrolab/back/lib/site'
import { useRouter } from 'nextra/hooks'
import NextraJSONDLGeneratedScripts from './pages_components/NextraJSONDLGeneratedScripts'
import { defaultLocale } from '@astrolab/web/constants'
import { FaGithub } from 'react-icons/fa'

const shouldSkipIndexing = isExperimentalEnvironment()

const config: DocsThemeConfig = {
  i18n: [
    { locale: 'en', name: 'English' },
    { locale: 'fr', name: 'Français' },
  ],
  logo: ThemedOctoBotLogo,
  logoLink: false,
  navbar: {
    extraContent: (
      <>
        <NavBarNextra />
        <NextraLocaleSwitch />
        <ThemeSwitch />
      </>
    ),
  },
  themeSwitch: {
    component: <></>,
  },
  docsRepositoryBase: 'https://github.com/Drakkar-Software/OctoBot',
  footer: {
    component: () => {
      const router = useRouter()
      return <NextraFooter locale={router.locale}/>
    },
  },
  head: function useHead() {
    const router = useRouter()
    const fsPath = useFSRoute()
    const metadata = getMetadata()
    const config = useConfig<{ description?: string; image?: string }>()
    const description = (config.frontMatter.description ||
      metadata.description ||
      'OctoBot') as string
    const image =
      config.frontMatter.image ||
      `${process.env.NEXT_PUBLIC_SITE_URL}/opengraph-image.png`
    const title = `${config.title} - OctoBot`
    const baseUrl = process.env.NEXT_PUBLIC_SITE_URL || ''
    const url = `${baseUrl}${fsPath}`

    return (
      <>
        <title>{title}</title>
        <link
          rel="icon"
          type="image/x-icon"
          sizes="16x16"
          href="/favicon.ico"
        />
        <meta name="description" content={description} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta httpEquiv="Content-Language" content={router.locale} />
        <meta name="apple-mobile-web-app-title" content="OctoBot" />
        <meta
          name="twitter:creator"
          content="https://twitter.com/DrakkarsOctoBot"
        />
        <meta name="twitter:site" content={metadata.twitter?.site} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={metadata.openGraph?.siteName} />
        <meta property="og:image" content={image} />
        <link rel="canonical" href={url} />
        {shouldSkipIndexing ? <link rel="nofollow" /> : null}
        <NextraJSONDLGeneratedScripts baseUrl={baseUrl} config={config} locale={router.locale || defaultLocale} />
      </>
    )
  },
  editLink: {
    component: () => <></>,
  },
  feedback: {
    content: () => <></>,
  },
  toc: {
    backToTop: true,
    title: () => {
      const router = useRouter()
      const title = router.locale == "fr" ? "Sur cette page": "On This Page"
      return <>{title}</>
    },
  },
  search: {
    placeholder: 'Search...',
  },
  project: {
    icon: <FaGithub size="22px"/>,
    link: 'https://github.com/Drakkar-Software/OctoBot',
  },
  sidebar: {
    autoCollapse: true,
    defaultMenuCollapseLevel: 1,
    // titleComponent: ({ title, type }) => <>{title}</>,
    toggleButton: true,
  },
}

export default config
